@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&family=Shadows+Into+Light&display=swap');

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centers content vertically */
  background-color: #faf9ef;
  min-height: 100vh; /* Ensures the container fills the viewport height */
  text-align: center;
}


.App-header {
  width: 100%; /* Full width */
  /* No need for top padding if .App has it */
}

.App-header h1 {
  font-family: "Rock Salt", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: xx-large;
  animation: fadeInDown 1s;
}

.App-logo {
  max-width: 100%; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
}

.App-explanation {
  margin-bottom: 1rem; /* Spacing after text */
  font-size: large;
}

.launchlist-widget {
  width: 300px; /* Adjust width as needed */
  margin-bottom: 1rem; /* Spacing after widget */
}
.App-contact {
  font-size: 1rem;
  margin-top: 1rem;
}
.App-instagram {
  font-size: 1.2rem;
  color: #c13584; /* Instagram's brand color */
  text-decoration: none;
}

.App-instagram i {
  margin-right: 0.5rem;
}


